import styled, { css } from "styled-components";
import { InputGroupProps } from "types/InputGroup";

export const ButtonTemplate = styled.div<InputGroupProps>`
  margin: 0 auto;
  width: 20px;
  padding: 30px 0 30px 0px;

  label {
    font-size: 1rem;
    color: ${props => props.theme.colors.muted};
  }

  ${props => props.noSpacing && css`
    margin-top: 0;
  `}

  ${props => props.flexItem && css`
    flex: 1 0 0;
  `}
`;
