import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'components/Form/Select';
import { useTemplates } from 'hooks/fetch/useTemplates';
import { useContacts } from 'hooks/useContacts';
import { useMessages } from 'hooks/useMessages';
import { MouseEvent, useEffect, useState } from 'react';
import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Modal from 'react-modal';
import CreatableSelect from 'react-select/creatable';
import { api } from 'services/api';
import { useTheme } from 'styled-components';
import { Button } from 'styles/Button';
import { Form } from 'styles/Form';
import { FormRow } from 'styles/FormRow';
import { InputGroup } from 'styles/InputGroup';
import { TemplateTextarea } from 'styles/TemplateTextarea';
import { ModalTitle } from 'styles/Modal';
import { ChatMessage } from 'types/ChatMessage';
import { isEmpty } from 'utils/Misc';
import { handleRequestError } from 'utils/Request';
import { v4 as uuidv4 } from 'uuid';
import closeImg from '../../assets/close.svg';
import { ActionsButtons, Header, Option, OptionIcons } from './styles';
import { CustomTemplateData, TemplateModalProps, SelectTemplateData } from './types';
import { templateMessageValidator } from './validation';
import { Template } from 'types/Template';

const validationSchema = templateMessageValidator();

export function TemplateModal({ isOpen, onRequestClose, canSendMessage }: TemplateModalProps) {
  const { data, isLoading } = useTemplates();

  const {
    messagesQuery: { query },
    selectedQuotedMessage,
    setSelectedQuotedMessage,
    setPreviewMessages,
    setIsRefetchingMessages,
  } = useMessages();

  const form = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      customTemplate: '',
    },
  });

  const { handleSubmit, setValue, control, reset, formState } = form;

  useEffect(() => {
    if (!isOpen) return;

    if (!canSendMessage) {
      setValue('annotation', true);
    } else {
      setValue('annotation', false);
    }
  }, [canSendMessage, setValue, isOpen]);

  const { selectedContact } = useContacts();

  function handleRequestClose() {
    reset();
    onRequestClose();
  }

  async function handleSendTemplateMessage({ name_meta, annotation, message_preview }: CustomTemplateData) {
    const quoted = selectedQuotedMessage;

    setSelectedQuotedMessage({} as ChatMessage);

    if (!name_meta) return;

    setPreviewMessages(prevMessages => {
      return [
        ...prevMessages,
        {
          id: uuidv4(),
          body: message_preview,
          contact_id: selectedContact.id,
          type: 'chat',
          quoted: !isEmpty(quoted) ? quoted : '',
          caption: '',
          is_forwarded: false,
          time: new Date().toString(),
          from_me: true,
          is_read: true,
          is_annotation: annotation,
          ack: 'SENT',
        } as ChatMessage
      ]
    });

    try {
      await api.post('/messages/send-template', {
        contactId: selectedContact.id,
        name_meta: name_meta,
        quotedMsgId: !isEmpty(quoted) ? quoted.whatsapp_msg_id : null,
        isAnnotation: annotation,
      });

      setIsRefetchingMessages(true);
      await query.refetch();
      setPreviewMessages([]);
      setIsRefetchingMessages(false);
    } catch (error) {
      handleRequestError(error, 'Erro ao enviar mensagem. Por favor, tente novamente!');
    }

    reset();
    handleRequestClose();
  }

  function handleSelectTemplate(newValue: unknown) {
    setValue('name_meta', (newValue as SelectTemplateData).value.name_meta ?? '', { shouldDirty: true });
    setValue('customTemplate', (newValue as SelectTemplateData).value.message ?? '', { shouldDirty: true });
    setValue('message_preview', (newValue as SelectTemplateData).value.message ?? '', { shouldDirty: true });
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content big"
      >
        <button
          type="button"
          onClick={handleRequestClose}
          className="react-modal-close"
        >
          <img src={closeImg} alt="Fechar modal" loading="lazy" />
        </button>

        <div>
          <Header>
            <ModalTitle noSpacing>Enviar template</ModalTitle>
          </Header>

          <Form onSubmit={handleSubmit(handleSendTemplateMessage as SubmitHandler<FieldValues>)}>
            <FormProvider {...form}>
              <InputGroup>
                <Select
                  control={control}
                  name="selectTemplate"
                  id="selectTemplate"
                  label="Selecionar ou criar templates"
                  placeholder="Selecionar ou criar template"
                  onSelect={handleSelectTemplate}
                  fullwidth
                  config={{
                    noOptionsMessage: () => 'Nenhum template disponível.',
                    options: data ? data.map(template => ({ value: template, label: template.title })) : [],
                    closeMenuOnSelect: true,
                    isDisabled: isLoading,
                  }}
                >
                  {data && data.map((template: Template) => (
                    <option key={template.id} value={template.id}>
                      {template.title}
                    </option>
                  ))}
                </Select>
              </InputGroup>
              <InputGroup>
                <TemplateTextarea
                  control={control}
                  name="customTemplate"
                  id="customTemplate"
                  label="Mensagem"
                  placeholder="Mensagem do template"
                  rows={6}
                  fullwidth
                  disabled={true}
                />
              </InputGroup>
              <InputGroup>
                <Button
                  type="submit"
                  fullwidth
                  disabled={formState.isSubmitting}
                >
                  {formState.isSubmitting ? 'Carregando...' : 'Enviar'}
                </Button>
              </InputGroup>
            </FormProvider>
          </Form>
        </div>
      </Modal>
    </>
  );
}
