import { useEffect } from "react";
import { useQuery } from "react-query";
import { api } from "services/api";
import { Template } from "types/Template";
import { handleRequestError } from "utils/Request";

type TemplatesParams = {
  sort?: string;
}

export async function fetchTemplates(params?: TemplatesParams): Promise<Template[]> {
  const { data } = await api.get('/templates', { params });
  return data;
}

export function useTemplates(params?: TemplatesParams) {
  const query = useQuery('templates', () => fetchTemplates(params));

  useEffect(() => {
    if (!query.isError) return;
    handleRequestError(query.error, 'Ocorreu um erro ao listar os templates. Por favor, tente novamente!');
  }, [query.isError, query.error]);

  return query;
}
