import { Fragment, useEffect } from "react";
import { differenceInMinutes, parseISO } from "date-fns";

import { Table } from "styles/Table";

import { useContacts as useFetchContacts } from "hooks/fetch/useContacts";

import ReactTooltip from "react-tooltip";
import { Contact } from "components/Contact";
import { ContactsTableProps, ShowTransferredContactAlertData } from "./types";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Container, Loading, SmallLoading } from "./styles";
import { useSocket } from "hooks/useSocket";
import { isEmpty } from "utils/Misc";
import { playNotificationSound, playTransferredContactSound } from "utils/Audio";
import { api } from "services/api";
import { useContacts } from "hooks/useContacts";
import { handleRequestError } from "utils/Request";
import { useCount } from "hooks/utils/useCount";
import Swal from "sweetalert2";

export function ContactsTable({ endpoint }: ContactsTableProps) {
  const { loadMoreRef, query: { data, isLoading, isFetchingNextPage, refetch } } = useFetchContacts(endpoint);

  const { selectContact } = useContacts();

  const { channelPrivate, channelPublic } = useSocket();

  const { revalidateCount } = useCount();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data]);

  useEffect(() => {
    if (!channelPrivate || isEmpty(channelPrivate)) return;

    channelPrivate.listen('.NewUserContactMessage', () => {
      playNotificationSound();
    });
  }, [channelPrivate, refetch]);

  useEffect(() => {
    if (!channelPublic || isEmpty(channelPublic)) return;

    channelPublic.listen('.NewContactMessage', () => {
      playNotificationSound();
      revalidateCount();
    });

  }, [channelPublic, revalidateCount]);

  useEffect(() => {
    if (!channelPrivate || isEmpty(channelPrivate)) return;

    async function selectTransferredContact(id: string) {
      try {
        const { data } = await api.get(`/contacts/${id}`);
        selectContact(data);
      } catch (error) {
        handleRequestError(error, 'Erro ao selecionar contato. Por favor, tente novamente!');
      }
    }

    channelPrivate.listen('.TransferUser', async (e: any) => {
      playTransferredContactSound();
      revalidateCount();

      const willSelect = await Swal.fire({
        title: 'Novo contato transferido',
        text: 'Um contato foi transferido para você. Deseja selecionar este contato e dar início a conversa?',
        icon: 'info',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: 'Ignorar',
        confirmButtonText: 'Selecionar contato',
        allowEnterKey: false,
      });

      if (!willSelect.isConfirmed) return false;

      selectTransferredContact(e.data.contactId);
    });

  }, [channelPrivate, selectContact, revalidateCount]);

  return (
    <Container>
      {isLoading && !isFetchingNextPage && (
        <Loading>
          <LoadingSpinner size={25} thickness={2} />
        </Loading>
      )}

      {!isLoading && (
        <Table>
          <thead>
            <tr>
              <th>Contato</th>
              <th>Última interação</th>
              <th>Responsável</th>
              <th>Setor</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data && data.pages && data.pages.map((page, index) => (
              <Fragment key={index}>
                {page.data && page.data.map((contact) => {
                  // Aplica formatação de acordo com o tempo de espera do cliente
                  const talkStartedAt = contact.talk_started_at ?? '';
                  let startedOption = 3;

                  if (talkStartedAt != '') {
                    const minutesDifferenceStarted = differenceInMinutes(new Date(), parseISO(talkStartedAt));
                    if (minutesDifferenceStarted <= 10) {
                      startedOption = 1;
                    } else if (minutesDifferenceStarted <= 15) {
                      startedOption = 2;
                    }
                  }

                  return (
                    <Contact
                      key={contact.id}
                      contact={contact}
                      startedOption={startedOption}
                    />
                  );
                })}
              </Fragment>
            ))}
          </tbody>
        </Table>
      )}

      <div ref={loadMoreRef} style={{ visibility: 'hidden' }}></div>

      {isFetchingNextPage && (
        <SmallLoading>
          <LoadingSpinner size={25} thickness={2} />
        </SmallLoading>
      )}
    </Container>
  );
}
