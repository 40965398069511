import { rgba } from "polished";
import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;

  th {
    color: ${props => props.theme.colors.muted};
    font-weight: 400;
    text-align: left;
    line-height: 1.5rem;
    text-transform: uppercase;
    font-size: 0.875rem;
    border-bottom-width: calc(100% - 64px);
    border-bottom: 1px solid ${props => props.theme.colors.borderLight};
    padding-bottom: .5rem;

    &:first-child {
      font-weight: bold;
      padding-left: .5rem;
    }

    &:last-child {
      text-align: right;
      padding-right: .5rem;
    }
  }

  tbody tr {
    cursor: pointer;

    &.selected {
      background: ${props => props.theme.colors.selected};
    }

    &:not(.selected):not(.inactive):hover {
      background: ${props => props.theme.colors.hover};
    }

    &.started {
      background: ${props => rgba(111, 255, 122, 0.2)};

      &:hover {
        background: ${props => rgba(111, 255, 122, 0.3)};
      }

      &.selected {
        background: ${props => rgba(111, 255, 122, 0.4)};
      }
    }

    &.minutes10 {
      background: ${props => rgba(255, 225, 0, 0.2)};

      &:hover {
        background: ${props => rgba(255, 225, 0, 0.3)};
      }

      &.selected {
        background: ${props => rgba(255, 225, 0, 0.4)};
      }
    }

    &.minutes15 {
      background: ${props => rgba(255, 0, 0, 0.2)};

      &:hover {
        background: ${props => rgba(255, 0, 0, 0.3)};
      }

      &.selected {
        background: ${props => rgba(255, 0, 0, 0.4)};
      }
    }
  }

  td.loading {
    color: ${props => props.theme.colors.text};
    background: ${props => props.theme.colors.borderLight};
    text-align: center;
    padding: .5rem 0;
  }

  td:not(.loading) {
    height: 5.16rem;
    border: 0;
    color: ${props => props.theme.colors.text};
    line-height: 1.3;
    border-bottom: 1px solid ${props => props.theme.colors.borderLight};

    span:not(.separator) {
      display: inline-block;
      vertical-align: bottom;
    }

    &:first-child {
      display: flex;
      align-items: center;
      padding-left: .5rem;

      img {
        border-radius: 50%;
        width: 40px;

        @media only screen and (max-width: 1366px) {
          width: 30px;
        }
      }
    }

    &:last-child {
      text-align: right;
      padding-right: .5rem;
    }
  }
`;
